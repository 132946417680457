import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import useScrollLock from '../../../../../utils/useScrollLock'
import Dropbox from '../../../../Components/Dropbox'

import {TransporterGroupService} from '../../../../../services/CarrierServices/TransporterGroupService'

const CreateTransporterGroupMamber = ({onClose, createTransporterGroupMamber, setLoading}: any) => {
  const intl = useIntl()
  const transporterGroupServ = new TransporterGroupService()

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)

  const [selectedUser, setSelectedUser] = useState<string>('')

  const [transporterList, setTransporterList] = useState<any>([])

  const [userListScrollAPICalled, setUserListScrollAPICalled] = useState<boolean>(false)

  const {scrollLock} = useScrollLock()

  const getUserList = (pageIndex: number = 0) => {
    transporterGroupServ
      .getTransporterMemberList(pageIndex ?? 0)
      .then((resp: any) => {
        setUserListScrollAPICalled(false)
        if (pageIndex === 0)
          setTransporterList(resp?.data?.filter((item: any) => item?.transporterId))
        else setTransporterList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setUserListScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    scrollLock()
    getUserList()
  }, [])

  return (
    <RightLayoutPopup
      className='carrier-transporter-group-wrap'
      isCloseIcon
      title={intl.formatMessage({id: 'Carrier.transporterGroup.mamber.title'})}
      closeOnClick={() => onClose()}
    >
      <div className='row mt-10 mb-10 col-md-12'>
        <h6 className={'mb-3'}>
          {intl.formatMessage({id: 'Carrier.transporterGroup.mamber.transporter'})}
        </h6>
        <div className={''}>
          <Dropbox
            hideArrow
            customClass={'form-select dropBox-input-wrap'}
            placeholder={intl.formatMessage({id: 'Carrier.transporterGroup.mamber.transporter'})}
            customSearchValue={''}
            customOnSearch={(e: any) => {}}
            disabledSearch
            onScroll={(val: any) => {
              setCurrentPageIndex(val)
              getUserList(val)
            }}
            setScrollCalled={() => {
              setUserListScrollAPICalled(true)
            }}
            scrollAPIResponse={userListScrollAPICalled}
            currentPageIndex={currentPageIndex}
            isTemplate
            dropBoxClass={'dropBox-wrap'}
            menuClassWrap={selectedUser ? 'dropBox-menu-data-wrap' : 'dropBox-menu-wrap'}
            menuList={
              transporterList &&
              transporterList
                .filter(function ({transporterId}: any) {
                  // @ts-ignore
                  return !this[transporterId] && (this[transporterId] = transporterId)
                }, {})
                ?.map((item: any) => {
                  return {
                    id: item?.transporterId,
                    title: (
                      <div className='d-flex flex-column'>
                        <div className='d-flex row' style={{justifyContent: 'space-evenly'}}>
                          <div className='col-md-3'>
                            <b>{intl.formatMessage({id: 'Carrier.freight.details.name'})}:</b>
                          </div>
                          <div className='col-md-3'>{item?.driver?.name}</div>
                          <div className='col-md-3'>
                            <b>{intl.formatMessage({id: 'Carrier.freight.details.surName'})}:</b>
                          </div>
                          <div className='col-md-3'>{item?.driver?.surname}</div>
                        </div>
                        <div className='d-flex row' style={{justifyContent: 'space-evenly'}}>
                          <div className='col-md-3'>
                            <b>
                              {intl.formatMessage({
                                id: 'Carrier.transporterGroup.member.list.table.vehiclePlate',
                              })}
                              :
                            </b>
                          </div>
                          <div className='col-md-3'>{item?.vehicle?.plate}</div>
                          <div className='col-md-3'>
                            <b>
                              {intl.formatMessage({
                                id: 'Carrier.transporterGroup.member.list.table.trailerPlate',
                              })}
                              :
                            </b>
                          </div>
                          <div className='col-md-3'>{item?.trailer?.plate}</div>
                        </div>
                      </div>
                    ),
                  }
                })
            }
            selectedItemData={(item: any) => {
              if (item?.id) {
                setSelectedUser(item?.id)
              }
            }}
          />
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            type={'button'}
            disabled={!selectedUser}
            onClick={() => {
              onClose()
              setLoading(true)
              createTransporterGroupMamber(selectedUser)
            }}
          >
            {intl.formatMessage({id: 'Carrier.drivers.create.create'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {CreateTransporterGroupMamber}
