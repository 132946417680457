import React from 'react'
import {useIntl} from 'react-intl'

// import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const UnderstandInterModalFreight = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/intermodal_freight.png")'}}
      ></div>
      {/* <Seo
        data={{
          title: intl.formatMessage({id: 'blog.speedEfficAir.metaTitle'}),
          metaDescription: intl.formatMessage({id: 'blog.speedEfficAir.metaDesc'}),
          href: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
      </Seo> */}
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/' style={{ color: '#757575'}}>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.undertandInterModal.title'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl('/media/intermodal_freight.png')}
                                alt='Intermodal Freight'
                              />
                            </div>
                            <br />
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label'})}{' '}
                            <a href='https://www.rivalog.com/'>
                              <b>
                                {intl.formatMessage({
                                  id: 'blog.undertandInterModal.label1',
                                })}
                              </b>
                            </a>{' '}
                            {intl.formatMessage({id: 'blog.undertandInterModal.label2'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label3'})}
                          </h2>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label4'})}
                          </p>

                          <p className='point-content-wrap'>
                            <a href='https://www.rivalog.com/'>
                              <b>
                                {intl.formatMessage({
                                  id: 'blog.undertandInterModal.label5',
                                })}
                              </b>
                            </a>{' '}
                            {intl.formatMessage({id: 'blog.undertandInterModal.label6'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label7'})}
                          </h2>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label8'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label9'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label10'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label11'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label12'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label13'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label14'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label15'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label16'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label17'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label18'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.futureOfRoadTransportation.label19'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label20'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label21'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label22'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label23'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label24'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label25'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label26'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label27'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label28'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label29'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label30'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label31'})}
                          </p>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.undertandInterModal.label32'})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderstandInterModalFreight
