import {Tooltip as BsTooltip} from 'bootstrap'
import React, {useEffect, useRef} from 'react'

export const Tooltip = (p: {children: JSX.Element; text: any}) => {
  const childRef = useRef(undefined as unknown as Element)

  useEffect(() => {
    const t = new BsTooltip(childRef.current, {
      title: p.text,
      placement: 'right',
      trigger: 'hover',
      container: false,
      html: true,
      customClass: 'tooltip-custom-wrap',
    })
    return () => t.dispose()
  }, [p.text])

  return React.cloneElement(p.children, {ref: childRef})
}
