import React from 'react'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../../_metronic/helpers'

import './CompanyTypeSelector.scss'
interface Types {
  companyTypeList?: {
    key?: number
    title?: string
    icon?: 'truck' | 'container' | 'default'
  }[]
  companyType?: any
  selectedCompanyType?: any
}

const CompanyTypeSelector = ({companyTypeList, companyType, selectedCompanyType}: Types) => {
  const intl = useIntl()
  const registrationTitle: any = {
    ['FREIGHT_CARRIER']: intl.formatMessage({id: 'REGISTER.Freight.carrier'}),
    ['FREIGHT_OWNER']: intl.formatMessage({id: 'REGISTER.Freight.owner'}),
    ['FORWARDER']: intl.formatMessage({id: 'REGISTER.forwarder'}),
  }

  return (
    <div className={'company-type-selector-main'}>
      <h3 className={'mb-10 text-center'}>
        {intl.formatMessage({id: 'ADMIN.REGISTER.SELECTCOMPANYTYPE'})}
      </h3>
      <div className={'row '}>
        {companyTypeList &&
          companyTypeList?.map((item: any) => (
            <div
              key={item?.key}
              className={'col-md-6 selector-outer mb-4'}
              onClick={() => companyType(item?.key)}
            >
              <button
                className={`btn btn-active-color-primary ${
                  item?.key === selectedCompanyType ? 'selected-type' : ''
                } p-10 selector-inner w-100`}
              >
                <KTSVG
                  path={
                    item?.icon === 'truck'
                      ? '/media/icons/duotune/ecommerce/ecm006.svg'
                      : item?.icon === 'container'
                      ? '/media/icons/duotune/ecommerce/ecm009.svg'
                      : '/media/icons/duotune/files/fil001.svg'
                  }
                  className='svg-icon-5qx'
                />
                <h2 className={'mt-5'}>{registrationTitle[item.code]}</h2>
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}
export default CompanyTypeSelector
