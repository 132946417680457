import React from 'react'
import {useIntl} from 'react-intl'

// import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const HowToChooseRightTransportationRoadServices = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/how-to-choose-road-transport.jpg")'}}
      ></div>
      {/* <Seo
        data={{
          title: intl.formatMessage({id: 'blog.speedEfficAir.metaTitle'}),
          metaDescription: intl.formatMessage({id: 'blog.speedEfficAir.metaDesc'}),
          href: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
      </Seo> */}
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <div className='post-start-content-head-wrap'>
                          <p>{intl.formatMessage({id: 'blog.howToChooseRightTranspo.title'})}</p>
                        </div>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.howToChooseRightTranspo.title1'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl('/media/how-to-choose-road-transport.jpg')}
                                alt='max-effi'
                              />
                            </div>
                            <br />
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label1'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label2'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label3'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label4'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label5'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label6'})}
                          </p>

                          <div style={{marginLeft: '15px'}}>
                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label7'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label8'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label9'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label10'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label11'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label12'})}
                            </p>
                          </div>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label13'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label14'})}
                          </p>

                          <div style={{marginLeft: '15px'}}>
                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label15'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label16'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label17'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label18'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label19'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label20'})}
                            </p>
                          </div>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label21'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label22'})}
                          </p>

                          <div style={{marginLeft: '15px'}}>
                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label23'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label24'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label25'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label26'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label27'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label28'})}
                            </p>
                          </div>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label29'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label30'})}
                          </p>

                          <div style={{marginLeft: '15px'}}>
                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label31'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label32'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label33'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label34'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label35'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label36'})}
                            </p>
                          </div>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label37'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label38'})}
                          </p>

                          <div style={{marginLeft: '15px'}}>
                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label39'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label40'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label41'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label42'})}
                            </p>

                            <h4 className='point-content-wrap point-content-wrap-color-sub'>
                              ● {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label43'})}
                            </h4>
                            <p className='point-content-wrap-second point-content-wrap-sub'>
                              {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label44'})}
                            </p>
                          </div>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label45'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label46'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label47'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label48'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label49'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label50'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label51'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label52'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label53'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label54'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label55'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label56'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label57'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label58'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label59'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label60'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label61'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label62'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label63'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label64'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label65'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label66'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label67'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label68'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label69'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howToChooseRightTranspo.label70'})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToChooseRightTransportationRoadServices
