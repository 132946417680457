import React from 'react'
import {useIntl} from 'react-intl'

import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

const siteNavigationStructuredData: any = {
  '@context': 'http://schema.org',
  '@type': 'BlogPosting',
  name: 'blog post',
  url: 'https://www.rivalog.com/blog/the-evolution-of-freight-exchanges-from-traditional-to-online-plateforms',
  publisher: {
    '@type': 'Organization',
    name: 'Rivalog',
    logo: 'https://www.rivalog.com/media/logo.png',
  },
}

const EvolutionFreightExchanges = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/evolution-freight.png")'}}
      ></div>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.evoultionFreightExchanges.title'}),
          metaDescription: intl.formatMessage({id: 'Seo.evoultionFreightExchanges.description'}),
          href: 'https://www.rivalog.com/blog/the-evolution-of-freight-exchanges-from-traditional-to-online-plateforms',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
      </Seo>
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.evolutionfreight.title'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl('/media/evolution-freight.png')}
                                alt='evolution freight'
                              />
                            </div>
                            <br />
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label'})}{' '}
                            <a href='https://www.rivalog.com/'>
                              <b>{intl.formatMessage({id: 'blog.evolutionfreight.label1'})}</b>
                            </a>
                            , {intl.formatMessage({id: 'blog.evolutionfreight.label2'})}
                          </p>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label3'})}
                          </p>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label4'})}
                          </p>

                          <h2 className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label5'})}
                          </h2>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label6'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label7'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label8'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label9'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label10'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label11'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label12'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label13'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label14'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label15'})}
                          </p>

                          <h2 className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label16'})}
                          </h2>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label17'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.evolutionfreight.label18'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.evolutionfreight.label19'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.evolutionfreight.label20'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.evolutionfreight.label21'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.evolutionfreight.label22'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.evolutionfreight.label23'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.evolutionfreight.label24'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.evolutionfreight.label25'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.evolutionfreight.label26'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.evolutionfreight.label27'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.evolutionfreight.label28'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.evolutionfreight.label29'})}
                          </p>

                          <h3 className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label30'})}
                          </h3>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label31'})}
                          </p>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.evolutionfreight.label32'})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvolutionFreightExchanges
