import React from 'react'
import {useIntl} from 'react-intl'

import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

const siteNavigationStructuredData: any = {
  '@context': 'http://schema.org',
  '@type': 'BlogPosting',
  name: 'blog post',
  url: 'https://www.rivalog.com/blog/how-freight-exhcnages-improve-road-transportation-services',
  publisher: {
    '@type': 'Organization',
    name: 'Rivalog',
    logo: 'https://www.rivalog.com/media/logo.png',
  },
}

const FreightExchangeImproveServices = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/improveRoad.png")'}}
      ></div>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.howFreightExcImprvRoadTran.title'}),
          metaDescription: intl.formatMessage({id: 'Seo.howFreightExcImprvRoadTran.description'}),
          href: 'https://www.rivalog.com/blog/how-freight-exhcnages-improve-road-transportation-services',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
      </Seo>
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.freightExchImprove.title'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label'})}{' '}
                            <a href='https://www.rivalog.com/'>
                              <b>{intl.formatMessage({id: 'blog.freightExchImprove.label1'})}</b>
                            </a>
                            . {intl.formatMessage({id: 'blog.freightExchImprove.label2'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl('/media/improveRoad.png')}
                                alt='Improved Road'
                              />
                            </div>
                            <br />
                            {intl.formatMessage({id: 'blog.freightExchImprove.label3'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label4'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label5'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label6'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label7'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label8'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label9'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label10'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label11'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label12'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label13'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label14'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label15'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label16'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label17'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label18'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label19'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label20'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label21'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label22'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label23'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label24'})}{' '}
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label25'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label26'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label27'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label28'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label29'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label30'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label31'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label32'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label33'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label34'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label35'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label36'})}
                          </p>

                          <p className='point-content-wrap'>
                            <b>{intl.formatMessage({id: 'blog.freightExchImprove.label37'})}</b>{' '}
                            {intl.formatMessage({id: 'blog.freightExchImprove.label38'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label39'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label40'})}
                          </p>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.freightExchImprove.label41'})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreightExchangeImproveServices
