import React from 'react'
import {useIntl} from 'react-intl'

// import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const UnderstandingTheBasicOfFullTruckLoad = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/full-truck-load-freight.jpg")'}}
      ></div>
      {/* <Seo
        data={{
          title: intl.formatMessage({id: 'blog.speedEfficAir.metaTitle'}),
          metaDescription: intl.formatMessage({id: 'blog.speedEfficAir.metaDesc'}),
          href: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
      </Seo> */}
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <div className='post-start-content-head-wrap'>
                          <p>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.title'})}
                          </p>
                        </div>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.title1'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl('/media/full-truck-load-freight.jpg')}
                                alt='max-effi'
                              />
                            </div>
                            <br />
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label1'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label2'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label3'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label4'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label5'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label6'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label7'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label8'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.label9'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label10',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label11',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label12',
                            })}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label13',
                            })}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label14',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label15',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label16',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label17',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label18',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label19',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label20',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label21',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label22',
                            })}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label23',
                            })}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label24',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label25',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label26',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label27',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label28',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label29',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label30',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label31',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label32',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label33',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label34',
                            })}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label35',
                            })}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label36',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label37',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label38',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label39',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label40',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label41',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label42',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label43',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label44',
                            })}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label45',
                            })}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label46',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label47',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label48',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label49',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label50',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label51',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label52',
                            })}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label53',
                            })}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label54',
                            })}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label55',
                            })}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label56',
                            })}
                          </p>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({
                              id: 'blog.understandingTheBasicTruckLoad.label57',
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderstandingTheBasicOfFullTruckLoad
