import React from 'react'
import {useIntl} from 'react-intl'

const privacyContent: any = {
  en: '/privacy_policy_en/',
  tr: '/privacy_policy_tr/',
  ru: '/privacy_policy_ru/',
}

const deliveryRefundContent: any = {
  en: '/delivery_refund_en/',
  tr: '/delivery_refund_tr/',
  ru: '/delivery_refund_ru/',
}

const distanceSalesContent: any = {
  en: '/distance_sales_en/',
  tr: '/distance_sales_tr/',
  ru: '/distance_sales_ru/',
}

const FooterContent = () => {
  const intl = useIntl()

  return (
    <div className='footer-area'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-logo-wrap'>
            <div className='footer-logo'>
              <a href='/'>
                <img src='/media/footer-logo.png' alt='' />
              </a>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-links-wrap'>
            <div className='footer-link-area'>
              <div className='footer-heading'>
                <h4>{intl.formatMessage({id: 'public.newLandingpage.links'})}</h4>
              </div>
              <ul className='footer-quick-links'>
                <li>
                  <a href='/public/available-vehicles' target='_blank'>
                    {intl.formatMessage({id: 'freight.truckExchange.title'})}
                  </a>
                </li>
                <li>
                  <a href={deliveryRefundContent[intl.locale]} target='_blank'>
                    {intl.formatMessage({id: 'Footer.content.delivery'})}
                  </a>
                </li>
                <li>
                  <a href={distanceSalesContent[intl.locale]} target='_blank'>
                    {intl.formatMessage({id: 'Footer.content.roSales'})}
                  </a>
                </li>
                <li onClick={() => window.open('/agreement/tio-certificate.pdf')}>
                  <span>{intl.formatMessage({id: 'Footer.content.tioCert'})}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-blog-wrap-first'>
            <div className='footer-link-area'>
              <ul className='footer-quick-links' style={{marginTop: '2.4rem'}}>
                <li>
                  <a href='/blog/speed-and-efficiency-advantages-of-air' target='_blank'>
                    {intl.formatMessage({id: 'footer.blog.speedEff'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/how-freight-exhcnages-improve-road-transportation-services'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.freightExcImprove'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/the-evolution-of-freight-exchanges-from-traditional-to-online-plateforms'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.evoFreightExc'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/freight-exchange-trucking-streamlining-road-transportation'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.freghtExcTruck'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/air-freight-shipping-key-factors-driving-growth-and-efficiency'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.airFreghtShippingKey'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/maximizing-efficiency-the-benefits-of-full-truckload-freight'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.maximizEffieciency'})}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-blog-wrap-second'>
            <div className='footer-link-area'>
              <div className='footer-heading footer-heading-link'>
                <h4>Blog</h4>
              </div>
              <ul className='footer-quick-links'>
                <li>
                  <a
                    href='/blog/ocean-freight-shipping-navigating-the-waters-of-global-trade'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.oceanFreight'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/the-future-of-international-freight-exchanges-in-global-trade'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.theFutOfInter'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/unlocking-efficiency-the-evolution-and-benefits-of-freight-exchanges'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.unlockingEff'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/why-freight-exchange-platform-is-essential-for-modern-logistics'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.ehyFreightExch'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/top-strategies-for-effective-maritime-freight-forwarding-in-the-modern-era'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.topStrategiesForEff'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/understanding-intermodal-freight-transport-advantages-and-key-considerations'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.undertandingInterModal'})}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-blog-wrap-third'>
            <div className='footer-link-area'>
              <ul className='footer-quick-links' style={{marginTop: '2.4rem'}}>
                <li>
                  <a
                    href='/blog/how-ocean-freight-shipping-can-boost-your-business-growth'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.howOceanFreight'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/how-to-choose-the-right-road-transportation-service-for-your-needs'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.howToChooseRoad'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/the-future-of-buying-freight-trucks-why-online-sales-are-the-way-to-go'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.futureOfBuyingTruck'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/the-ultimate-guide-to-air-freight-shipping-services'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.ultimateGuideToAir'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/understanding-the-basics-of-full-truckload-transportation'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.understandFullTruck'})}
                  </a>
                </li>
                <li>
                  <a
                    href='/blog/the-future-of-road-transportation-service-what-you-need-to-know'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'footer.blog.futureOfRoadTransport'})}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-store-wrap'>
            <div className='app-store'>
              <a
                rel='noreferrer'
                href='https://play.google.com/store/apps/details?id=com.iwa.logistics'
                target='_blank'
              >
                <img src='/media/google-play.png' alt='' className='app-img-wrap' />
              </a>
              <a
                rel='noreferrer'
                href='https://apps.apple.com/us/app/rivalog/id1613475053'
                target='_blank'
              >
                <img src='/media/app-store.png' alt='' className='app-img-wrap' />
              </a>
            </div>
          </div> */}
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-contact-wrap'>
            <div className='footer-link-area footer-contact'>
              <div className='footer-heading'>
                <h4>{intl.formatMessage({id: 'Footer.content.contact'})}</h4>
              </div>
              <ul className='footer-contact'>
                <li>
                  <span>
                    <img src='/media/img10.svg' alt='' />
                  </span>
                  <a href='tel:+90 850 242 25 85'>+90 850 242 25 85</a>
                </li>
                <li>
                  <span>
                    <img src='/media/img11.svg' alt='' />
                  </span>
                  <a href='https://wa.me/905306284164' target='_blank'>
                    +90 530 628 41 64
                  </a>
                </li>
                <li>
                  <span>
                    <img src='/media/img12.svg' alt='' />
                  </span>
                  <a href='mailto:contact@rivalog.com'>contact@rivalog.com</a>
                </li>
              </ul>
            </div>
            <div className='app-store'>
              <a
                rel='noreferrer'
                href='https://play.google.com/store/apps/details?id=com.iwa.logistics'
                target='_blank'
              >
                <img src='/media/google-play.png' alt='' className='app-img-wrap' />
              </a>
              <a
                rel='noreferrer'
                href='https://apps.apple.com/us/app/rivalog/id1613475053'
                target='_blank'
              >
                <img src='/media/app-store.png' alt='' className='app-img-wrap' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {FooterContent}
