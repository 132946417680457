import React from 'react'
import {useIntl} from 'react-intl'

import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/the-evolution-of-freight-exchanges-from-traditional-to-online-plateforms',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const WhyFlightExchangePlatform = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/what-freight-exchange-platform.png")'}}
      ></div>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.whyFlightExchngPlateform.title'}),
          metaDescription: intl.formatMessage({id: 'Seo.whyFlightExchngPlateform.description'}),
          href: 'https://www.rivalog.com/blog/why-freight-exchange-platform-is-essential-for-modern-logistics',
        }}
      >
        {/* <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script> */}
      </Seo>
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.whyFreightExchangePlat.title'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl('/media/what-freight-exchange-platform.png')}
                                alt='what freight exchange platform'
                              />
                            </div>
                            <br />
                          </h2>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label'})}
                          </p>

                          <p className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label1'})}{' '}
                            <a href='https://www.rivalog.com/'>
                              <b>
                                {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label2'})}
                              </b>
                            </a>
                            , {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label3'})}
                          </p>

                          <h2 className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label4'})}
                          </h2>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label5'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label6'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label7'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label8'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label9'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label10'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label11'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label12'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label13'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label14'})}
                          </p>

                          <h2 className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label15'})}
                          </h2>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label16'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label17'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label18'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label20'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label21'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label22'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label23'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label24'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label25'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label26'})}
                          </p>

                          <h2 className='point-content-wrap'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label27'})}
                          </h2>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label28'})}
                          </p>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.whyFreightExchangePlat.label29'})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyFlightExchangePlatform
