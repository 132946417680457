import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useHistory, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {RootState} from '../../../../setup'
import Loading from '../../../Components/Loading'
import ViewFreightPosting from '../FreightPostings/Popup/ViewFreightPosting'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import CreateNewFreightPopup from '../../../Components/CreateNewFreightPopup'

import {PageTitle} from '../../../../_metronic/layout/core'
import getDateTime from '../../../../utils/common'

import {FreightPostingsService} from '../../../../services/CarrierServices/FreightPostingsServices'
import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {ContainerService} from '../../../../services/AdminServices/ContainerService'
import {Header} from '../../DefaultPages/NewHomePage/Header/Header'

import './PublicContainerDetails.scss'

const PublicContainerDetails = () => {
  const intl = useIntl()
  const history = useHistory()
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()
  /**
   ** UTILS
   **/
  const frieghtPostServ = new FreightPostingsService()
  const advertServ = new AdvertService()
  const containerServ = new ContainerService()

  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const [loading, setLoading] = useState<boolean>(false)
  const [noDataFound, setNoDataFound] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [pageTransporterIndex, setPageTransporterIndex] = useState<number>(0)
  const [containerDetails, setContainerDetails] = useState<any>({})
  const [currencyList, setCurrencyList] = useState<any>([])
  const [selectedTransporterType, setSelectedTransporterType] = useState<any>(null)
  const [selectedTransporterId, setSelectedTransporterId] = useState<any>(null)
  const [selectedFreightPost, setSelectedFreightPost] = useState<any>(null)

  /*List States*/
  const [freightPostingList, setFreightPostingList] = useState<any[]>([])
  const [proposalScopeList, setProposalScopeList] = useState<any[]>([])
  const [transporterList, setTransporterList] = useState<any[]>([])
  const [selectedTransporterContainer, setSelectedTransporterContainer] = useState<any[]>([])

  /*Screens and modals*/

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  /**
   *! API CALL START
   **/
  const getFreightPostingList = (pageIndex?: number, containerId?: any) => {
    frieghtPostServ
      .getPublicFreightPostingsByContainerId(pageIndex, containerId)
      .then((resp: any) => {
        setFreightPostingList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  /**
   *! API CALL END
   **/

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getFreightPostingList(page, query?.get('containerId'))
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const onTransporterPaginationChange = (page: number) => {
    getTransporterList(page)
    setPageTransporterIndex(page)
  }

  const getPublicContainerDetails = (containerId?: any) => {
    frieghtPostServ
      .getPublicContainerDetails(containerId)
      .then((resp: any) => {
        setLoading(false)
        setContainerDetails(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCurrencyList = () => {
    advertServ
      .currencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getProposalScopeList = () => {
    containerServ
      .getCarrierProposalScope()
      .then((resp: any) => {
        setProposalScopeList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getTransporterList = (pageIndex?: number) => {
    frieghtPostServ
      .getTransporterList(pageIndex ?? 0)
      .then((resp: any) => {
        setTransporterList(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const createFreightPost = (price: any, currency: any, proposalScope: any, description: any) => {
    frieghtPostServ
      .createTransportApplication(
        query?.get('containerId'),
        selectedTransporterId,
        price,
        currency,
        proposalScope,
        description
      )
      .then((resp: any) => {
        setLoading(false)
        setShowCreatePopup(false)
        toast.success(intl.formatMessage({id: 'Carrier.freightPosting.create.Success'}))
        getFreightPostingList(pageMIndex, query.get('containerId'))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
    setSelectedTransporterType(null)
    setSelectedTransporterId(null)
  }

  const getTransporterContainerList = (containerId?: number) => {
    frieghtPostServ
      .getSelectedTransporterList(containerId)
      .then((resp: any) => {
        setSelectedTransporterContainer(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    if (query?.get('containerId') && query?.get('containerId') !== null) {
      setLoading(true)
      getFreightPostingList(pageMIndex, query.get('containerId'))
      getPublicContainerDetails(query.get('containerId'))
    } else {
      setNoDataFound(true)
    }
  }, [])

  return (
    <>
      <div className='main-content-home-wrap'>
        <Header />
      </div>
      {noDataFound && (
        <div className='no-data-wrap db-body-area db-area-content-wrap'>
          <img style={{width: '25%'}} src='/media/track.png' alt='truck' />
          <h1>{intl.formatMessage({id: 'global.noDataFound'})}</h1>
        </div>
      )}
      <div className='db-body-area db-area-content-wrap'>
        {!noDataFound && (
          <div>
            <div className={'d-flex justify-content-end'}>
              <button
                className={'btn btn-primary'}
                onClick={() => {
                  if (!isAuthorized) {
                    history.push({pathname: '/login', state: {from: '/public/containers/'}})
                  } else {
                    setShowCreatePopup(true)
                    setLoading(true)
                    getCurrencyList()
                    getProposalScopeList()
                    getTransporterList(pageTransporterIndex)
                  }
                }}
              >
                {intl.formatMessage({id: 'DataTableAdvanced.buttons.create'})}
              </button>
            </div>
            <div>
              <div className='row'>
                <div className='col-md-3 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Carrier.freightPosting.description'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({id: 'Carrier.freightPosting.description'})}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={containerDetails?.description}
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='col-md-3 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Freight.createAdvert.freightContentType',
                    })}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={containerDetails?.freightContentType?.description}
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='col-md-3 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Freight.createAdvert.trailerType'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({id: 'Freight.createAdvert.trailerType'})}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={containerDetails?.trailerType?.description}
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='col-md-3 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Admin.Adverts.table_weight'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({id: 'Admin.Adverts.table_weight'})}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={containerDetails?.weight}
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='col-md-4 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Carrier.freightPosting.fromAddress'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({id: 'Carrier.freightPosting.fromAddress'})}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={
                      containerDetails?.fromAddress?.city.name +
                      ', ' +
                      containerDetails.fromAddress?.country.name
                    }
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='col-md-4 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Carrier.freightPosting.toAddress'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({id: 'Carrier.freightPosting.toAddress'})}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={
                      containerDetails?.toAddress?.city.name +
                      ', ' +
                      containerDetails.toAddress?.country.name
                    }
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>

                <div className='col-md-4 mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'Carrier.freightPosting.plannedDepartureDate'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Carrier.freightPosting.plannedDepartureDate',
                    })}
                    className={'form-control form-control-lg form-control-solid bg-white'}
                    value={
                      (containerDetails?.plannedDepartureDate !== null &&
                        getDateTime(containerDetails?.plannedDepartureDate, intl.locale)) ||
                      ''
                    }
                    type='text'
                    name='name'
                    disabled
                    autoComplete='off'
                  />
                </div>
              </div>
              <div>
                {loading && <Loading />}
                <PageTitle breadcrumbs={[]}>
                  {intl.formatMessage({id: 'SideMenu.forwarderOperator.freightPosts'})}
                </PageTitle>

                <div>
                  {!freightPostingList.length && (
                    <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
                      {intl.formatMessage({id: 'Carrier.freightPosting.noData'})}
                    </div>
                  )}
                  <DataTableAdvanced
                    columns={[
                      {
                        Header: 'ID',
                        accessor: 'id',
                      },
                      {
                        Header: intl.formatMessage({id: 'Freight.createAdvert.freightContentType'}),
                        accessor: 'freightContentType',
                      },
                      {
                        Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
                        accessor: 'company',
                      },
                      {
                        Header: intl.formatMessage({id: 'Carrier.freightPosting.fromAddress'}),
                        accessor: 'fromAddress',
                      },
                      {
                        Header: intl.formatMessage({id: 'Carrier.freightPosting.toAddress'}),
                        accessor: 'toAddress',
                      },
                      {
                        Header: intl.formatMessage({
                          id: 'Carrier.freightPosting.plannedDepartureDate',
                        }),
                        accessor: 'plannedDepartureDate',
                      },
                      {
                        Header: intl.formatMessage({id: 'Admin.Adverts.table_weight'}),
                        accessor: 'weight',
                      },
                    ]}
                    editableRows={['']}
                    data={
                      freightPostingList &&
                      freightPostingList?.map((item: any) => {
                        return {
                          id: item?.freightId,
                          freightContentType: item.freightContentType?.description,
                          company: item?.company?.name,
                          fromAddress:
                            item?.fromAddress?.city.name + ', ' + item?.fromAddress?.country.name,
                          toAddress:
                            item?.toAddress?.city.name + ', ' + item?.toAddress?.country.name,
                          plannedDepartureDate:
                            item?.plannedDepartureDate !== null &&
                            getDateTime(item?.plannedDepartureDate, intl.locale),
                          weight: item?.weight,
                        }
                      })
                    }
                    showDetailBtn={(row: any) => {
                      if (!isAuthorized) {
                        history.push('/auth/login')
                      } else {
                        setLoading(true)
                        setShowDetailsPopup(true)
                        getTransporterContainerList(row.values.id)
                        setSelectedFreightPost(row.values)
                      }
                    }}
                    setPageMIndex={onPaginationChange}
                    pageMIndex={pageMIndex}
                  />
                </div>

                {showCreatePopup && (
                  <CreateNewFreightPopup
                    onSubmit={(price: any, currency: any, proposalScope: any, description: any) => {
                      setLoading(true)
                      createFreightPost(price, currency, proposalScope, description)
                    }}
                    currencyList={currencyList}
                    proposalScopeList={proposalScopeList}
                    transporterList={transporterList}
                    selectedTransporterType={selectedTransporterType}
                    setSelectedTransporterId={setSelectedTransporterId}
                    setSelectedTransporterType={setSelectedTransporterType}
                    onClose={() => setShowCreatePopup(false)}
                    pageTransporterIndex={pageTransporterIndex}
                    onTransporterPaginationChange={onTransporterPaginationChange}
                  />
                )}

                {showDetailsPopup && (
                  <ViewFreightPosting
                    freightPostingList={selectedTransporterContainer}
                    onClose={() => {
                      setSelectedTransporterContainer([])
                      setShowDetailsPopup(false)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {PublicContainerDetails}
