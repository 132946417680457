import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

import Dropbox from '../../../../Components/Dropbox'

import {getCountries} from '../../../../../utils/common'
import {KTSVG} from '../../../../../_metronic/helpers'

import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'
import {VehiclesService} from '../../../../../services/CarrierServices/VehiclesService'

const FilterPanel = ({
  getFreightPostingList,
  filterData,
  selectedCountryNameFilter,
  setSelectedCountryNameFilter,
}: any) => {
  const intl = useIntl()

  const commonServ = new CommonService()
  const vehicleServ = new VehiclesService()

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])
  const [transportTypeList, setTransportTypeList] = useState<any>([])
  const [freightTypeList, setFreightTypeList] = useState<any>([])

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('')

  const [transportTypeSearch, setTransportTypeSearch] = useState<string>('')
  const [transportTypePageIndex, setTransportTypePageIndex] = useState<number>(0)
  const [transportTypeId, setTransportTypeId] = useState<string>('')

  const [freightTypeSearch, setFreightTypeSearch] = useState<string>('')
  const [freightTypePageIndex, setFreightTypePageIndex] = useState<number>(0)
  const [freightTypeId, setFreightTypeId] = useState<string>('all')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)
  const [transportTypeScrollAPICalled, setTransportTypeScrollAPICalled] = useState<boolean>(false)

  const [freightTypeScrollAPICalled, setFreightTypeScrollAPICalled] = useState<boolean>(false)

  useEffect(() => {
    getFreightPostingList({
      fromCountryCode: fromCountryId,
      fromCityId,
      toCountryCode: toCountryId,
      toCityId,
      transportTypeId,
      freightTypeId: freightTypeId === 'all' ? '' : freightTypeId,
    })
  }, [fromCountryId, fromCityId, toCountryId, toCityId, transportTypeId, freightTypeId])

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  useEffect(() => {
    getFromCountryList()
    getToCountryList()
    getTransportTypeList()
    getFreightTypeList()
  }, [])

  useEffect(() => {
    if (
      filterData &&
      Object.keys(filterData).length > 0 &&
      filterData?.fromCountryCode !== '' &&
      selectedCountryNameFilter &&
      Object.keys(selectedCountryNameFilter)?.length > 0 &&
      selectedCountryNameFilter?.selectedFromFilterCountry !== ''
    ) {
      setFromCountryId(filterData?.fromCountryCode)
      setFromCityId('')
      setDependentFromCity(true)
      setSelectedFromFilterCountry(selectedCountryNameFilter?.selectedFromFilterCountry)
    }

    if (
      filterData &&
      Object.keys(filterData).length > 0 &&
      filterData?.toCountryCode !== '' &&
      selectedCountryNameFilter &&
      Object.keys(selectedCountryNameFilter)?.length > 0 &&
      selectedCountryNameFilter?.selectedToFilterCountry !== ''
    ) {
      setToCountryId(filterData?.toCountryCode)
      setToCityId('')
      setDependentToCity(true)
      setSelectedToFilterCountry(selectedCountryNameFilter?.selectedToFilterCountry)
    }
  }, [filterData, selectedCountryNameFilter])

  const getFreightTypeList = (pageIndex: number = 0, searchText?: any) => {
    vehicleServ
      .getFreightPublicTypes(pageIndex ?? 0, 20, searchText ?? '')
      .then((resp: any) => {
        setFreightTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setFreightTypeList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.description))
          )
        else setFreightTypeList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFreightTypeScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .publicCountrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0) setFromCountryList(resp?.data)
        else setFromCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .publicCitySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCityList(resp?.data)
        else setFromCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .publicCountrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0) setToCountryList(resp?.data)
        else setToCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .publicCitySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0) setToCityList(resp?.data)
        else setToCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTransportTypeList = (pageIndex: number = 0, searchText?: any) => {
    commonServ
      .publicTransportTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTransportTypeScrollAPICalled(false)
        if (pageIndex === 0) setTransportTypeList(resp?.data)
        else setTransportTypeList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setTransportTypeScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  return (
    <div className='row justify-content-between' style={{padding: '10px'}}>
      <div className='mb-10 col-md-2'>
        <h6 className={'mb-3'}>
          {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
        </h6>
        <div className={''}>
          <Dropbox
            key={selectedFromFilterCountry}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'addNewAddressPopup.area2.country.placeholder',
            })}
            customSearchValue={fromCountrySearch}
            customOnSearch={(e: any) => {
              setFromCountryPageIndex(0)
              setFromCountrySearch(e.target.value)
              getFromCountryList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setFromCountryPageIndex(val)
              getFromCountryList(val, fromCountrySearch)
            }}
            setScrollCalled={() => {
              setFromCountryScrollAPICalled(true)
            }}
            scrollAPIResponse={fromCountryScrollAPICalled}
            currentPageIndex={fromCountryPageIndex}
            isTemplate
            menuList={
              fromCountryList &&
              fromCountryList
                ?.filter(function ({code}: any) {
                  // @ts-ignore
                  return !this[code] && (this[code] = code)
                }, {})
                ?.map((item: any) => {
                  return {
                    id: item?.code,
                    title: (
                      <div className='countryWrap'>
                        <KTSVG path={getCountries[item?.code]} className='svg-icon-2x' />
                        {item?.name}
                      </div>
                    ),
                  }
                })
            }
            defaultClearItem={{
              id: '',
              title: '',
            }}
            defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setSelectedCountryNameFilter({
                  ...selectedCountryNameFilter,
                  selectedFromFilterCountry: '',
                })
                setFromCountryId(item?.id)
                setSelectedFromFilterCountry(item?.title)
                setDependentFromCity(true)
                setFromCityId('')
              }

              if (item?.id === '' && item?.title === '') {
                setFromCountryId('')
                setSelectedFromFilterCountry('')
                setDependentFromCity(true)
                setFromCityId('')
              }
            }}
          />
        </div>
      </div>

      <div className='mb-10 col-md-2'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}</h6>
        <div className={''}>
          <Dropbox
            hideArrow
            disabled={!fromCountryId || fromCountryId === ''}
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
            customSearchValue={fromCitySearch}
            customOnSearch={(e: any) => {
              setFromCityPageIndex(0)
              setFromCitySearch(e.target.value)
              getFromSearchCityList(fromCountryId, 0, e.target.value)
            }}
            onScroll={(val: any) => {
              if (fromCountryId) {
                setFromCityPageIndex(val)
                getFromSearchCityList(fromCountryId, val, fromCountrySearch)
              }
            }}
            setScrollCalled={() => {
              setFromCityScrollAPICalled(true)
            }}
            scrollAPIResponse={fromCityScrollAPICalled}
            currentPageIndex={fromCityPageIndex}
            menuList={
              fromCityList &&
              fromCityList
                ?.filter(function ({cityId}: any) {
                  // @ts-ignore
                  return !this[cityId] && (this[cityId] = cityId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.cityId, title: item?.name}
                })
            }
            defaultClearItem={{
              id: '',
              title: '',
            }}
            defaultSelected={{id: fromCityId}}
            dependentValue={dependentFromCity}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setFromCityId(item?.id)
                setDependentFromCity(false)
              }

              if (item?.id === '' && item?.title === '') {
                setFromCityId('')
                setDependentFromCity(false)
              }
            }}
          />
        </div>
      </div>

      <div className='mb-10 col-md-2'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}</h6>
        <div className={''}>
          <Dropbox
            key={selectedToFilterCountry}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'addNewAddressPopup.area2.country.placeholder',
            })}
            customSearchValue={toCountrySearch}
            customOnSearch={(e: any) => {
              setToCountryPageIndex(0)
              setToCountrySearch(e.target.value)
              getToCountryList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setToCountryPageIndex(val)
              getToCountryList(val, toCountrySearch)
            }}
            setScrollCalled={() => {
              setToCountryScrollAPICalled(true)
            }}
            defaultClearItem={{
              id: '',
              title: '',
            }}
            scrollAPIResponse={toCountryScrollAPICalled}
            defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
            currentPageIndex={toCountryPageIndex}
            isTemplate
            menuList={
              toCountryList &&
              toCountryList
                ?.filter(function ({code}: any) {
                  // @ts-ignore
                  return !this[code] && (this[code] = code)
                }, {})
                ?.map((item: any) => {
                  return {
                    id: item?.code,
                    title: (
                      <div className='countryWrap'>
                        <KTSVG path={getCountries[item?.code]} className='svg-icon-2x' />
                        {item?.name}
                      </div>
                    ),
                  }
                })
            }
            selectedItemData={(item: any) => {
              if (item?.id) {
                setSelectedCountryNameFilter({
                  ...selectedCountryNameFilter,
                  selectedToFilterCountry: '',
                })
                setToCountryId(item?.id)
                setSelectedToFilterCountry(item?.title)
                setDependentToCity(true)
                setToCityId('')
              }

              if (item?.id === '' && item?.title === '') {
                setToCountryId('')
                setSelectedToFilterCountry('')
                setDependentToCity(true)
                setToCityId('')
              }
            }}
          />
        </div>
      </div>

      <div className='mb-10 col-md-2'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}</h6>
        <div className={''}>
          <Dropbox
            hideArrow
            disabled={!toCountryId || toCountryId === ''}
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
            customSearchValue={toCitySearch}
            customOnSearch={(e: any) => {
              setToCityPageIndex(0)
              setToCitySearch(e.target.value)
              getToSearchCityList(toCountryId, 0, e.target.value)
            }}
            onScroll={(val: any) => {
              if (toCountryId) {
                setToCityPageIndex(val)
                getToSearchCityList(toCountryId, val, fromCountrySearch)
              }
            }}
            setScrollCalled={() => {
              setToCityScrollAPICalled(true)
            }}
            scrollAPIResponse={toCityScrollAPICalled}
            currentPageIndex={toCityPageIndex}
            dependentValue={dependentToCity}
            menuList={
              toCityList &&
              toCityList
                ?.filter(function ({cityId}: any) {
                  // @ts-ignore
                  return !this[cityId] && (this[cityId] = cityId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.cityId, title: item?.name}
                })
            }
            defaultClearItem={{
              id: '',
              title: '',
            }}
            defaultSelected={{id: toCityId}}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setToCityId(item?.id)
                setDependentToCity(false)
              }

              if (item?.id === '' && item?.title === '') {
                setToCityId('')
                setDependentToCity(false)
              }
            }}
          />
        </div>
      </div>

      <div className='mb-10 col-md-2'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'Freight.myAdvert.transportType'})}</h6>
        <div className={''}>
          <Dropbox
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'Freight.myAdvert.transportType'})}
            customSearchValue={transportTypeSearch}
            customOnSearch={(e: any) => {
              setTransportTypePageIndex(0)
              setTransportTypeSearch(e.target.value)
              getTransportTypeList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setTransportTypePageIndex(val)
              getTransportTypeList(val, transportTypeSearch)
            }}
            setScrollCalled={() => {
              setTransportTypeScrollAPICalled(true)
            }}
            scrollAPIResponse={transportTypeScrollAPICalled}
            currentPageIndex={transportTypePageIndex}
            menuList={
              transportTypeList &&
              transportTypeList
                ?.filter(function ({transportTypeId}: any) {
                  // @ts-ignore
                  return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.transportTypeId, title: item?.description}
                })
            }
            defaultSelected={{id: transportTypeId}}
            defaultClearItem={{
              id: '',
              title: '',
            }}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setTransportTypeId(item?.id)
              }

              if (item?.id === '' && item?.title === '') {
                setTransportTypeId('')
              }
            }}
          />
        </div>
      </div>

      <div className='mb-10 col-md-2'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'Freight.createAdvert.freightType'})}</h6>
        <div className={''}>
          <Dropbox
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'Freight.createAdvert.freightType',
            })}
            customSearchValue={freightTypeSearch}
            customOnSearch={(e: any) => {
              setFreightTypePageIndex(0)
              setFreightTypeSearch(e.target.value)
              getFreightTypeList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setFreightTypePageIndex(val)
              getFreightTypeList(val, freightTypeSearch)
            }}
            setScrollCalled={() => {
              setFreightTypeScrollAPICalled(true)
            }}
            scrollAPIResponse={freightTypeScrollAPICalled}
            currentPageIndex={freightTypePageIndex}
            menuList={
              freightTypeList &&
              freightTypeList
                ?.filter(function ({freightTypeId}: any) {
                  // @ts-ignore
                  return !this[freightTypeId] && (this[freightTypeId] = freightTypeId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.freightTypeId, title: item?.description}
                })
            }
            defaultClearItem={{
              id: '',
              title: '',
            }}
            defaultSelected={{id: freightTypeId}}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setFreightTypeId(item?.id)
              }
              if (item?.id === '' && item?.title === '') {
                setFreightTypeId('')
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export {FilterPanel}
