import React from 'react'
import {useIntl} from 'react-intl'

// import Seo from '../../../../utils/seo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const HowOceanFreightBoostYourBusiness = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div
        className='bg-photo-overlay'
        style={{backgroundImage: 'url("/media/aerial-view-cargo-ship-cargo-container-harbor.jpg")'}}
      ></div>
      {/* <Seo
        data={{
          title: intl.formatMessage({id: 'blog.speedEfficAir.metaTitle'}),
          metaDescription: intl.formatMessage({id: 'blog.speedEfficAir.metaDesc'}),
          href: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
      </Seo> */}
      <div className='page-body'>
        <div className='centered-wrap-main'>
          <header className='centered-top-container animating' role='banner'>
            <div className='centered-top'>
              <div className='blog-name container'>
                <div className='container section' id='header'>
                  <div className='widget Header' data-version='2' id='Header1'>
                    <div className='header-widget'>
                      <div>
                        <h1>
                          <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                        </h1>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <nav role='navigation'>
                  <div className='clearboth no-items section' id='page_list_top'></div>
                </nav>
              </div>
            </div>
          </header>

          <div>
            <main className='centered-bottom' role='main'>
              <div className='main section'>
                <div className='widget'>
                  <article className='post-outer-container'>
                    <div className='post-outer'>
                      <div className='post'>
                        <div className='post-start-content-head-wrap'>
                          <p>{intl.formatMessage({id: 'blog.howOceanFreightBoost.title'})}</p>
                        </div>
                        <h3 className='post-title entry-title'>
                          {intl.formatMessage({id: 'blog.howOceanFreightBoost.title1'})}
                        </h3>
                        <div className='post-header'>
                          <span className='byline'>{date.toDateString()}</span>
                        </div>
                        <div className='post-body'>
                          <h2 className='img-road-wrap'>
                            <div className='separator-wrap'>
                              <img
                                className='improved-img-wrap'
                                src={toAbsoluteUrl(
                                  '/media/aerial-view-cargo-ship-cargo-container-harbor.jpg'
                                )}
                                alt='max-effi'
                              />
                            </div>
                            <br />
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label1'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label2'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label3'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label4'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label5'})}
                          </h2>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label6'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label7'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label8'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label9'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label10'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label11'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label12'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label13'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label14'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label15'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label16'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label17'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label18'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label19'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label20'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label21'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label22'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label23'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label24'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label25'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label26'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label27'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label28'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label29'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label30'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label31'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label32'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label33'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label34'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label35'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label36'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label37'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label38'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label39'})}
                          </p>

                          <h3 className='point-content-wrap point-content-wrap-color'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label40'})}
                          </h3>

                          <p className='point-content-wrap-second'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label41'})}
                          </p>

                          <h2 className='img-road-wrap'>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label42'})}
                          </h2>

                          <p style={{textAlign: 'left'}}>
                            {intl.formatMessage({id: 'blog.howOceanFreightBoost.label43'})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowOceanFreightBoostYourBusiness
