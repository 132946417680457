import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'

import {FilterPanel} from './FilterPanel'
import {priceConversion, getCountries, getDate, getTime} from '../../../../../utils/common'
import {RootState} from '../../../../../setup'
import {Tooltip} from './TooltipContent'
import CardComponent from '../CardComponent/CardComponent'

import {ListingService} from '../../../../../services/FreightOperatorServices/ListingService'
import {FreightPostingsService} from '../../../../../services/CarrierServices/FreightPostingsServices'

const CenterContent = () => {
  const intl = useIntl()
  const [listingData, setListingData] = useState<any>([])
  const [freightPostingList, setFreightPostingList] = useState<any[]>([])
  const [filterData, setFilterData] = useState<any>({})
  const [isFilter, setIsFilter] = useState<boolean>(false)

  const [pageIndex, setPageIndex] = useState<any>(0)
  const [publicPageIndex, setPublicPageIndex] = useState<any>(0)
  const [defaultData, setDefaultData] = useState<any>([])
  const [announcementLists, setAnnouncementLists] = useState<any>([])
  const [advertisementListData, setAdevrtisementListData] = useState<any>([])
  const [dashboardDetails, setDashboardDetails] = useState<any>({})

  const [selectedCountryNameFilter, setSelectedCountryNameFilter] = useState<any>({})

  const listingServ = new ListingService()
  const frieghtPostServ = new FreightPostingsService()

  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const role: any = useSelector<RootState>(({auth}) => auth.role, shallowEqual)

  const history = useHistory()

  const advertisementList = () => {
    listingServ
      .getPublicAdvertisementDisplayList({advertisementTypeId: '2'})
      .then((resp: any) => {
        setAdevrtisementListData(
          resp?.data?.length > 0
            ? resp?.data?.map((item: any, index: any) => ({...item, index: (index + 1) * 5}))
            : resp?.data
        )
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getPublicContainerDashboardList = () => {
    listingServ
      .getPublicContainerDashboard()
      .then((resp: any) => setDashboardDetails(resp?.data))
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getListingData = useCallback(
    (data: any) => {
      listingServ
        .getLandingPublicListings(data)
        .then((resp: any) => {
          if (publicPageIndex === 0) {
            setDefaultData(resp?.data)
          }
          setPublicPageIndex(publicPageIndex + 1)
          if (resp?.data.length === 0) {
            setPublicPageIndex(0)
            setListingData(defaultData)
          } else {
            setListingData(resp?.data)
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message + ' | ' + e.response.data.code)
        })
    },
    [publicPageIndex]
  )

  const getAnnouncementList = () => {
    listingServ
      .getAnnouncementList()
      .then((resp: any) => {
        setAnnouncementLists(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFreightPostingList = (data: any) => {
    frieghtPostServ
      .getLandingPublicFreightPostings(data)
      .then((resp: any) => {
        setFreightPostingList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    getFreightPostingList({pageIndex})
    getAnnouncementList()
    advertisementList()
    getPublicContainerDashboardList()

    if (isAuthorized) {
      if (role?.includes('CARRIER_OPERATOR')) {
        history.push('/carrier/freight-postings')
      } else if (role?.includes('FORWARDER_OPERATOR')) {
        history.push('/forwarder/freight-postings')
      } else if (role?.includes('SYSTEM_OPERATOR')) {
        history.push('/admin/freight-postings')
      }
    }
  }, [])

  useEffect(() => {
    if (publicPageIndex === 0) {
      getListingData({pageIndex: publicPageIndex})
    } else {
      const interval = setInterval(() => getListingData({pageIndex: publicPageIndex}), 15000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [publicPageIndex])

  const handlePaginationChange = (pageIndex: any) => {
    getFreightPostingList({pageIndex, ...filterData})
    setPageIndex(pageIndex)
  }

  const advertRender = (item: any) => {
    return (
      <tr>
        <td colSpan={12}>
          <div className={'col-xl-12 p-2 mb-2'} key={item?.advertisementId}>
            <div
              className='img-advert-table-content-wrap'
              style={{cursor: item?.targetUrl ? 'pointer' : 'auto'}}
              onClick={() => (item?.targetUrl ? window.open(item?.targetUrl, '_blank') : () => {})}
            >
              <img src={item?.defaultImage} alt='adevrtisement' className='img-advert-table-wrap' />
            </div>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div className='db-body-area'>
      <div className='container-fluid'>
        <div className='db-body-area-inner'>
          <div className='row'>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12'>
              <div className='db-card db-cardstyle-1'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon'>
                      <img src='/media/img14.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'freight.truckExchange.title'})}</h3>
                  </div>
                  <div className='dropdown dropdown-style1'>
                    <button
                      className='btn btn-secondary dropdown-toggle'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <img src='/media/img13.svg' alt='drop' />
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <a
                          className='dropdown-item'
                          onClick={() => history.push('/public/available-vehicles')}
                        >
                          {intl.formatMessage({id: 'public.newLandingPage.view'})}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='db-card-content'>
                  <div className='map' onClick={() => history.push('/public/available-vehicles')}>
                    <img src='/media/map.svg' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <div className='db-card db-cardstyle-1'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon'>
                      <img src='/media/img15.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'freight.truckBuyingSelling.title'})}</h3>
                  </div>
                  <div className='dropdown dropdown-style1'>
                    <button
                      className='btn btn-secondary dropdown-toggle'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <img src='/media/img8.svg' />
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <a
                          className='dropdown-item'
                          onClick={() => history.push('/public/vehicle-buying-selling')}
                        >
                          {intl.formatMessage({id: 'public.newLandingPage.showAll'})}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='db-card-content'>
                  <div className='Vehicle-buyselling-area'>
                    {listingData?.map((item: any) => (
                      <div className='Vehicle-buyselling-area-item'>
                        <div className='Vehicle-buyselling-img'>
                          <img src={item?.defaultImage} />
                        </div>
                        <ul className='Vehicle-buyselling-info'>
                          <li>
                            <h6>{intl.formatMessage({id: 'freight.myListing.postingNumber'})}</h6>
                            <p>{item?.listingId}</p>
                          </li>
                          <li>
                            <h6>{intl.formatMessage({id: 'freight.myListing.model'})}</h6>
                            <p>{item?.model}</p>
                          </li>
                          <li>
                            <h6>{intl.formatMessage({id: 'freight.listing.city'})}</h6>
                            <p>{item?.city?.name}</p>
                          </li>
                          <li>
                            <h6>{intl.formatMessage({id: 'freight.listing.country'})}</h6>
                            <p>{item?.country?.name}</p>
                          </li>
                          <li>
                            <h6>{intl.formatMessage({id: 'freight.listing.brand'})}</h6>
                            <p>{item?.brand?.name}</p>
                          </li>
                          <li>
                            <p className='elipsis-wrap'>{item?.description}</p>
                          </li>
                        </ul>
                        <div className='Vehicle-buyselling-button-area'>
                          <span className='darkgray-tag'>
                            {priceConversion(item?.price, item?.currency?.currencyCode)}{' '}
                            {item?.currency?.currencyCode}
                          </span>
                          <button
                            type='button'
                            className='secondary-button-36'
                            onClick={() =>
                              history.push(`/public/vehicle-buying-selling/${item?.listingId}`)
                            }
                          >
                            <span>
                              <img src='/media/img3.svg' />
                            </span>
                            {intl.formatMessage({id: 'freight.listing.show'})}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12'>
              <div className='db-card db-cardstyle-1'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon'>
                      <img src='/media/img16.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'public.newLandingPage.userAnnounce'})}</h3>
                  </div>
                  <div className='dropdown dropdown-style1'>
                    <button
                      className='btn btn-secondary dropdown-toggle'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <img src='/media/img8.svg' />
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <a className='dropdown-item' onClick={() => history.push('/auth/login')}>
                          {intl.formatMessage({id: 'public.newLandingPage.view'})}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='db-card-content no-announcement-main'>
                  <div
                    className={`user-announcements-list-area ${
                      !(announcementLists?.length > 0) ? 'no-announcement-content ' : ''
                    }`}
                  >
                    {announcementLists?.length > 0 ? (
                      announcementLists?.map((item: any) => (
                        <div className='user-announcements-item'>
                          <div className='icon'>
                            <img src='/media/marketing.png' alt='' />
                          </div>
                          <div className='user-announcements-item-right'>
                            <Tooltip
                              text={`<div><h3>${item?.title}</h3>
    <p>${item?.content}</p></div>`}
                            >
                              <h4>{item?.title}</h4>
                            </Tooltip>
                            <p>{getDate(item?.issueDate)}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className='d-flex justify-content-center'>
                          <img
                            src='/media/marketing.png'
                            alt='announcement'
                            style={{width: '125px', height: '125px'}}
                          />
                        </div>
                        <div className='d-flex justify-content-center' style={{padding: '45px'}}>
                          <h2>{intl.formatMessage({id: 'public.newLandingPage.noAnnounce'})}</h2>
                        </div>
                      </>
                    )}
                    {/* <div className='user-announcements-item'>
                      <div className='icon'>
                        <img src='images/img18.svg' alt='' />
                      </div>
                      <div className='user-announcements-item-right'>
                        <h4>License No</h4>
                        <p>RZECZPOSPOLITAPOLSKA</p>
                      </div>
                    </div>
                    <div className='user-announcements-item'>
                      <div className='icon'>
                        <img src='images/img18.svg' alt='' />
                      </div>
                      <div className='user-announcements-item-right'>
                        <h4>Vehicle No</h4>
                        <p>PO 3S802</p>
                      </div>
                    </div>
                    <div className='user-announcements-item'>
                      <div className='icon'>
                        <img src='images/img18.svg' alt='' />
                      </div>
                      <div className='user-announcements-item-right'>
                        <h4>License No</h4>
                        <p>RZECZPOSPOLITAPOLSKA</p>
                      </div>
                    </div>
                    <div className='user-announcements-item'>
                      <div className='icon'>
                        <img src='images/img18.svg' alt='' />
                      </div>
                      <div className='user-announcements-item-right'>
                        <h4>Driver Name</h4>
                        <p>John Doe</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              <CardComponent
                dashboardDetails={dashboardDetails}
                handleExternalFilterOpen={() => {
                  if (!isFilter) {
                    setIsFilter(!isFilter)
                  }
                }}
                handleExternalFilterData={(data: any) => {
                  if (Object.keys(data).length > 0) {
                    setFilterData({...filterData, ...data})
                    setTimeout(() => {
                      getFreightPostingList({pageIndex, ...filterData, ...data})
                    }, 500)
                  } else {
                    Object.keys(filterData).forEach((i) => (filterData[i] = null))
                    setFilterData({...filterData})
                    setTimeout(() => {
                      getFreightPostingList({pageIndex})
                    }, 500)
                  }
                }}
                handleExternalCountryName={(data: any) => {
                  if (data) {
                    setSelectedCountryNameFilter({...selectedCountryNameFilter, ...data})
                  } else {
                    Object.keys(selectedCountryNameFilter).forEach(
                      (i) => (selectedCountryNameFilter[i] = null)
                    )
                    setSelectedCountryNameFilter({...selectedCountryNameFilter})
                  }
                }}
                isFilter={isFilter}
              />
            </div>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              <div className='db-card db-cardstyle-1'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon'>
                      <img src='/media/img17.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'public.newLandingPage.freightExc'})}</h3>
                  </div>
                  <div
                    className={`dropdown dropdown-style1`}
                    onClick={() => setIsFilter(!isFilter)}
                  >
                    <span
                      className={`btn btn-secondary ${
                        isFilter ? 'filterEnabled' : 'filterdisabled'
                      } filter-span-wrap`}
                      // type='button'
                      // data-bs-toggle='dropdown'
                      // aria-expanded='false'
                    >
                      <img src='/media/img13.svg' />
                    </span>
                    {/* <ul className='dropdown-menu'>
                      <li onClick={() => history.push('/auth/login')}>
                        <a className='dropdown-item'>
                          {intl.formatMessage({id: 'public.newLandingPage.view'})}
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                {isFilter && (
                  <FilterPanel
                    getFreightPostingList={(data: any) => {
                      setFilterData(data)
                      getFreightPostingList({pageIndex, ...data})
                    }}
                    filterData={filterData}
                    selectedCountryNameFilter={selectedCountryNameFilter}
                    setSelectedCountryNameFilter={setSelectedCountryNameFilter}
                  />
                )}
                <div className='db-card-content p-0'>
                  <div className='table-area'>
                    <div className='table-inner'>
                      <table className='table table-striped'>
                        {freightPostingList?.length > 0 &&
                          freightPostingList?.map((item: any, index: any) => (
                            <>
                              {advertisementListData?.length > 0 &&
                                !!advertisementListData.find(
                                  (dataItem: any) => dataItem?.index === pageIndex * 20 + index
                                ) &&
                                advertRender(
                                  advertisementListData.find(
                                    (dataItem: any) => dataItem?.index === index
                                  )
                                )}
                              <tr>
                                <td>
                                  <p>
                                    <strong>{item?.freightContentType?.description}</strong>
                                  </p>
                                </td>
                                <td>
                                  <p>{item?.freightType?.description}</p>
                                </td>
                                <td>
                                  <p>{item?.transportType?.description}</p>
                                </td>
                                <td>
                                  <p>{item?.trailerType?.description}</p>
                                </td>
                                <td>
                                  <p>
                                    <strong>
                                      {item?.price &&
                                        priceConversion(
                                          item?.price,
                                          item?.priceCurrency?.currencyCode
                                        )}{' '}
                                      {item?.priceCurrency?.currencyCode}
                                    </strong>
                                  </p>
                                </td>
                                <td>
                                  <div className='flagwithcont'>
                                    <div className='flag'>
                                      <img src={getCountries[item?.fromAddress?.country?.code]} />
                                    </div>
                                    <p>
                                      {item?.fromAddress?.city.name +
                                        ', ' +
                                        item?.fromAddress?.country.name}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className='flagwithcont'>
                                    <div className='flag'>
                                      <img src={getCountries[item?.toAddress?.country?.code]} />
                                    </div>
                                    <p>
                                      {item?.toAddress?.city.name +
                                        ', ' +
                                        item?.toAddress?.country.name}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <p>
                                    {item?.plannedDepartureDate &&
                                      getDate(item?.plannedDepartureDate)}
                                  </p>
                                  <span>
                                    {item?.plannedDepartureDate &&
                                      getTime(item?.plannedDepartureDate)}
                                  </span>
                                </td>
                                <td>
                                  <div className='dropdown dropdown-style1'>
                                    <button
                                      className='btn btn-secondary dropdown-toggle'
                                      type='button'
                                      data-bs-toggle='dropdown'
                                      aria-expanded='false'
                                    >
                                      <img src='/media/img8.svg' />
                                    </button>
                                    <ul className='dropdown-menu cursor-pointer'>
                                      <li onClick={() => history.push('/auth/login')}>
                                        <a className='dropdown-item'>
                                          {intl.formatMessage({id: 'public.newLandingPage.view'})}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                      </table>
                    </div>
                    <div className='pagination-area'>
                      <div className='pagination-text'>
                        <p>
                          {intl.formatMessage({id: 'public.newLandingPage.showing'})}{' '}
                          {pageIndex * 10 + 1} to {pageIndex * 10 + freightPostingList.length}
                          {/* of 19,420 entries */}
                        </p>
                      </div>
                      <div className='pagination-btn'>
                        <nav aria-label='Page navigation example'>
                          <ul className='pagination'>
                            <li className={`page-item ${pageIndex === 0 ? 'cursor-banned' : ''}`}>
                              <a
                                className={`page-link ${pageIndex === 0 ? 'content-disabled' : ''}`}
                                onClick={() => handlePaginationChange(pageIndex - 1)}
                                aria-label='Previous'
                              >
                                <span aria-hidden='true'>
                                  <img src='/media/l-Icon.png' />
                                </span>
                              </a>
                            </li>
                            {/* <!-- <li class="page-item">
                              <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">...</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">47</a>
                            </li> --> */}
                            <li
                              className={`page-item ${
                                freightPostingList?.length < 10 ? 'cursor-banned' : ''
                              }`}
                            >
                              <a
                                className={`page-link ${
                                  freightPostingList?.length < 10 ? 'content-disabled' : ''
                                }`}
                                onClick={() => handlePaginationChange(pageIndex + 1)}
                                aria-label='Next'
                              >
                                <span aria-hidden='true'>
                                  <img src='/media/r-Icon.png' />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12'>
              <div className='db-card db-cardstyle-2'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon darkskyblue'>
                      <img src='/media/img4.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'public.newLandingPage.onlyYNeed'})}</h3>
                  </div>
                </div>
                <div className='db-card-content'>
                  <p>{intl.formatMessage({id: 'public.newLandingPage.onlyYNeed.desc'})}</p>
                </div>
                <div className='db-card-footer'>
                  <button
                    type='button'
                    className='primary-button-36'
                    onClick={() => history.push('/auth/login')}
                  >
                    {intl.formatMessage({id: 'public.newLandingPage.readMore'})}
                  </button>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12'>
              <div className='db-card db-cardstyle-2'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon green'>
                      <img src='/media/img5.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'sidemenu.vehicleTrack.title'})}</h3>
                  </div>
                </div>
                <div className='db-card-content'>
                  <p>{intl.formatMessage({id: 'public.newLandingPage.vehicleTrack.desc'})}</p>
                </div>
                <div className='db-card-footer'>
                  <button
                    type='button'
                    className='primary-button-36'
                    onClick={() => history.push('/auth/login')}
                  >
                    {intl.formatMessage({id: 'public.newLandingPage.readMore'})}
                  </button>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12'>
              <div className='db-card db-cardstyle-2'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon purple'>
                      <img src='/media/img6.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'sidemenu.privateFreightExg.title'})}</h3>
                  </div>
                </div>
                <div className='db-card-content'>
                  <p>{intl.formatMessage({id: 'public.newLandingPage.privatefreightExc.desc'})}</p>
                </div>
                <div className='db-card-footer'>
                  <button
                    type='button'
                    className='primary-button-36'
                    onClick={() => history.push('/auth/login')}
                  >
                    {intl.formatMessage({id: 'public.newLandingPage.readMore'})}
                  </button>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12'>
              <div className='db-card db-cardstyle-2'>
                <div className='db-card-header'>
                  <div className='left'>
                    <div className='icon gray900'>
                      <img src='/media/img7.svg' />
                    </div>
                    <h3>{intl.formatMessage({id: 'public.newLandingPage.prefroutes'})}</h3>
                  </div>
                </div>
                <div className='db-card-content'>
                  <p>{intl.formatMessage({id: 'public.newLandingPage.prefroutes.desc'})}</p>
                </div>
                <div className='db-card-footer'>
                  <button
                    type='button'
                    className='primary-button-36'
                    onClick={() => history.push('/auth/login')}
                  >
                    {intl.formatMessage({id: 'public.newLandingPage.readMore'})}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CenterContent}
