import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import {Header} from './Header/Header'
import Seo from '../../../../utils/seo'
import {CenterContent} from './CenterContent/CenterContent'
import {FooterContent} from './FooterContent/FooterContent'

import './NewHomePage.scss'

const siteNavigationStructuredData: any = {
  '@context': 'https://schema.org',
  '@type': 'SiteNavigationElement',
  name: 'Rivalog',
  url: 'https://www.rivalog.com/',
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: ' https://www.rivalog.com/?s={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  ],
}

const websiteStructuredData: any = {
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: 'Rivalog',
  url: 'https://www.rivalog.com/',
  potentialAction: {
    '@type': 'SearchAction',
    target: ' https://www.rivalog.com/?s={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
}

const NewHomePage = () => {
  const intl = useIntl()

  return (
    <div className='main-content-home-wrap'>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.homepage.title'}),
          metaDescription: intl.formatMessage({id: 'Seo.homepage.description'}),
          href: 'https://www.rivalog.com/',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
        <script type='application/ld+json'>{JSON.stringify(websiteStructuredData)}</script>
      </Seo>
      <h1>{intl.formatMessage({id: 'Seo.homepage.h1.tag'})}</h1>
      <Header />
      <CenterContent />
      <FooterContent />
    </div>
  )
}

export {NewHomePage}
